@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.title {
  font-size: 16px;
}

.label {
  font-size: 14px;
  color: $black;
}
