@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  .title {
    font-size: 14px;
    font-weight: 500;
  }

  .currentDescriptionList {
    list-style-type: none;
    padding: 0;
  }
}
