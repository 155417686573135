@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.itemContainer {
  padding: 10px 24px;
  border-bottom: 1px solid rgb(210, 210, 210);
  cursor: pointer;

  &:hover {
    background-color: rgba(45, 44, 46, 0.04);
  }
}

.address {
  color: $brown_2;
}
