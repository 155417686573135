.tableContainer {
  > div:first-child {
    height: 224px;
  }

  button {
    height: auto;
    padding: 2px 4px;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
  }

  .row {
    td {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      td:first-child {
        text-transform: none;
      }
    }
  }
}
