.customs {
  :global .input-group {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .currencyDropdown {
    width: 110px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .customsValueInput {
    border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
