@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.profiles {
  border-radius: 8px;
  background: #fff;
  padding: 16px 24px;
  margin: $grid-gutter-half-width 0;

  .title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  div {
    margin: 0;
  }

  .row {
    margin-left: -$grid-gutter-half-width;
    margin-right: -$grid-gutter-half-width;
    align-items: center;
  }
}
