.printUnprintedModal {
  > div {
    width: 330px;
  }

  .buttonContainer {
    padding-right: 0;

    .button {
      margin-top: 3px;
    }
  }
}
