@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.mydpdRadio {
  margin-bottom: 12px;

  :global .form-check-input {
    box-shadow: none;
  }

  :global .form-check-input:checked::after {
    background-color: $red;
    border: solid 1px $red;
  }

  :global .form-check-label {
    font-size: 10px;
    line-height: 18px;
  }
}
