@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.text {
  font-size: 10px;
  line-height: 150%;
  white-space: pre-line;

  > p {
    margin: 0;
  }
}

.uppercase {
  text-transform: uppercase;
}
