@import "~@dpdgroupuk/mydpd-ui/styles/bootstrap";

.cardContainer {
  @include media-breakpoint-down(md) {
    height: 652px;
  }
  height: 611px;
}

.tableContainer {
  height: 352px;

  thead {
    transform: translateY(-1px);
  }
}

.electronTableContainer {
  height: 367px;
}

.higherTableContainer {
  height: 412px;

  thead {
    transform: translateY(-1px);
  }
}

.higherElectronTableContainer {
  height: 427px;
}

.table {
  th:last-child,
  td:last-child {
    min-width: 94px;
  }
}

.electronTable {
  tr {
    height: 31.5px;

    td input[type="checkbox"] {
      height: 13px;
    }
  }
}
