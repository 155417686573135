@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.col {
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.row {
  margin: -$grid-gutter-width (-2 * $grid-gutter-width);
  width: calc(100% + #{4 * $grid-gutter-width});

  :global .mydpd-card {
    margin: 0;
  }
}
