.editImporterDialog {
  max-height: 800px;

  :global #edit-importer-modal {
    max-width: 692px;
  }

  .importerType {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    line-height: 2;
  }

  .typeRelatedField {
    padding-left: 40px;
  }
}

.helpModalContainer {
  text-align: justify;
}
