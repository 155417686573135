@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.title {
  margin-bottom: 18px;
  font-weight: bold;
}

.centeredTitle {
  margin-bottom: 18px;
  font-weight: bold;
  text-align: center;
}

.card {
  height: 100%;
}

.linkButtonGroupLeft {
  justify-content: flex-start;
  gap: 16px;
  background-color: transparent;
  padding: 16px 0;

  :global .mydpd-btn {
    margin: 0;
  }
}

.linkButtonGroup {
  justify-content: space-between;
  background-color: transparent;
  padding: 16px 0;

  :global .mydpd-btn {
    margin: 0;
  }
}

.tabs {
  padding: 0;

  .tabsNavigation {
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 20px;

    &::before {
      display: none;
    }

    .tabsNavItem {
      a {
        font-size: 18px;
        font-weight: bold;
        padding: 0;
        color: rgba(0, 0, 0, 0.54);
      }

      a::before {
        content: "";
      }

      a[class~="active"] {
        color: $black;
        text-decoration: underline;
        text-decoration-color: $red;
      }
    }

    .tabsNavItem + .tabsNavItem {
      margin-left: 16px;
    }
  }

  .tabsContentItem {
    padding-bottom: 0;
  }
}

.title > div {
  font-size: 14px;
}

.body {
  span {
    font-size: 11px;
  }
}

.packageDetailsCard,
.customsDetailsCard {
  height: 100%;
}

.packageDetailsCardTitle,
.customsDetailsCardTitle {
  padding-bottom: 15px;
  margin: 0;
}

.showMoreAction {
  justify-content: left;
}
