@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.underlined {
  border-bottom: 1px solid $blue_1;
}

.actionLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }

  .text {
    margin-right: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.0025em;
    color: $blue_1;
    cursor: pointer;
  }

  .text + svg {
    cursor: pointer;
  }
}
