@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.infoCard {
  padding: $grid-gutter-width;
  border-color: $rolling_stone;
  border-radius: 8px;

  .infoCardContainer {
    color: $red;
    display: flex;

    p {
      margin: 0;
      font-size: 10px;
      text-transform: uppercase;
    }
  }

  svg {
    margin-right: 10px;
  }
}
