@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.pickupList {
  list-style-type: none;
  padding-left: 0;
}

.title {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.67);
  white-space: nowrap;
}

.seeMoreButton {
  align-self: flex-end;
}

.radio {
  :global .form-check-input {
    cursor: pointer;
    box-shadow: none;
  }

  :global .form-check-label {
    cursor: pointer;
    font-size: 14px;
  }
}

.radioDisabled {
  :global .form-check-input {
    cursor: default;
  }

  :global .form-check-label {
    cursor: default;
    opacity: 0.6;
  }
}

.titleDisabled {
  opacity: 0.5;
}
