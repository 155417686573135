@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.col {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  > div {
    width: 100%;
  }
}
