@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.searchForm {
  .searchInput {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
    }
  }

  .searchFormButtons {
    height: 100%;
    padding-bottom: 28px;
    justify-content: flex-end;

    :global .mydpd-btn {
      margin: 0;
    }
  }

  .searchFormListWrapper {
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    h6 {
      font-size: 11px;
      font-weight: bold;
      margin-bottom: 14px;
    }
  }
}

.searchFormList {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
}

.searchFormListItem {
  margin: 0 8px 14px;

  &:last-of-type {
    margin-right: 0;
  }

  span {
    margin: 0 2px;

    &:last-of-type {
      color: rgba(#000, 0.67);
    }
  }
}
