@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.detailsContainer {
  span {
    padding-right: 10px;
  }
}

.open {
  color: $forest_green;
}

.closed {
  color: $red;
}
