@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid $brown;
  border-radius: 4px;
  padding-right: 8px;
  height: 1.875rem;
  line-height: 1.875rem;

  .leftContainer {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
  }

  .sequenceNumber {
    padding: 0 8px;
    border-right: 1px solid $brown;
    height: inherit;
  }

  .textContainer {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .text {
    padding-left: 8px;
    outline: 0 solid transparent;
  }

  .iconContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    background: none;
    cursor: pointer;
  }

  p,
  svg {
    margin: 0;
  }
}

.item:focus-within {
  border-color: $blue_1;
  color: $blue_1;
  caret-color: $blue_1;

  .sequenceNumber {
    border-right-color: $blue_1;
  }
}
