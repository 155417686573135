@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  height: 70vh;
}

.sectionContainer {
  padding: 8px 16px;
}

.partnerLogoContainer {
  display: flex;
  gap: 2px;
  position: relative;
}

.image {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.pickupDetails {
  overflow-y: auto;
}

.divider {
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
}

.address {
  color: $brown_2;
}

.pickupLabel {
  position: absolute;
  right: 15px;
  bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}
