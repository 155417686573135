@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.reviewCard {
  padding: $grid-gutter-width;
  border-color: $rolling_stone;

  .title {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;

    > div {
      flex-grow: 1;
    }
  }

  .titleWithGap {
    margin-bottom: 1.125rem;
  }

  .text {
    font-size: 10px;
    line-height: 150%;
    white-space: pre-line;

    > p {
      margin: 0;
    }
  }

  svg {
    margin-right: 8px;
  }

  .label {
    display: flex;
    justify-content: flex-end;
    height: 14px;
  }

  .body {
    padding: 0;
    font-size: 10px;
  }
}

.rounded {
  border-radius: 8px;
}
