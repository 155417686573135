@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.toggleButton {
  height: 30px;
  background-color: $white !important;
  color: $black !important;

  &:hover {
    background-color: #f6f6f6 !important;
  }
}

.amenitiesFilterButton {
  cursor: pointer;
}

.amenitiesFilterToggle {
  &::after {
    display: none !important;
  }
}

.amenitiesFilterMenu {
  min-width: 215px !important;
}

.checkIcon {
  fill: $light_gray_2;
}

.searchInputContainer {
  border-bottom: 1px solid $ship_gray;
}

.searchInput {
  border-width: 0;
}

.filterButton {
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }

  &:hover {
    background-color: $white !important;
    color: $black !important;
  }

  &:active {
    outline: 0;
    box-shadow: none;
  }
}

.filterButtonSelected {
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
    background-color: $red !important;
    border-color: $red !important;
  }

  &:active {
    outline: 0;
    box-shadow: none;
    background-color: $red;
    border-color: $red;
  }

  &:hover {
    background-color: $red !important;
    border-color: $red !important;
  }
}
