@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.tableContainer {
  min-height: 520px;
  height: 100%;
}

.invalidTableRow {
  background: rgba(220, 0, 50, 0.1);
  border-bottom: 1px solid rgba(220, 0, 50, 0.2);

  &:hover {
    background: rgba(220, 0, 50, 0.3) !important;
    border-bottom: 1px solid rgba(220, 0, 50, 0.7) !important;
  }
}

.table {
  td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.text {
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
}

.text16 {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
}

.text400 {
  font-weight: 400;
}

.textError {
  color: $red;
}

.textFixedWidth {
  max-width: 166px;
}

.textAlignEnd {
  // bootstrap lg breakpoint
  @media (min-width: 992px) {
    text-align: end;
  }
}

.findCommodityButton {
  margin-left: 8px;
  margin-bottom: 29px;
}

.buttonsWrapper {
  @media (min-width: 816px) and (max-width: 992px) {
    flex-wrap: nowrap;
  }
}

.buttonsStart {
  @media (max-width: 448px), (min-width: 768px) and (max-width: 815px) {
    justify-content: flex-end !important;
    margin-bottom: 1em;
  }
}
