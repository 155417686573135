@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.flexRow {
  display: flex;
  flex-direction: row;
}

.container {
  justify-content: space-between;

  .title {
    font-size: 14px;
  }

  .info {
    font-size: 11px;
    font-weight: normal;
    align-self: center;

    div + div {
      margin-left: 32px;
    }

    span {
      color: $black_medium;
      display: inline-block;
      margin-left: 8px;
    }
  }
}

.parcels {
  font-size: 11px;
}
