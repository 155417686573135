.title {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;

  > div {
    flex-grow: 1;
  }
}
