@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.col {
  display: flex;
  align-items: stretch;

  > div {
    width: 100%;
  }
}

.actionButtons {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $brown;
  opacity: 0.94;

  button,
  a {
    color: $black;
  }
}
