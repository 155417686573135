@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  min-width: 500px;
}

.body {
  padding: 0 !important;
  height: 70vh;
}

.mapContainer {
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
  height: 45%;
  position: relative;
}

.mapShadow {
  display: flex;
  width: 100%;
  height: calc(100% + 10px);
  position: absolute;
  z-index: 9;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  cursor: default;
}

.listContainer {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 10;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.expandListButton {
  position: absolute;
  z-index: 10;
  top: -40px;
  border: none !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
}

.expandedList {
  height: calc(100% - 55px);
}

.collapsedList {
  height: calc(55%);
}

.pickupPointsListContainer {
  height: calc(100% - 60px);
  overflow-x: auto;
}

.headerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  cursor: pointer;
}
