@import "~normalize.css/normalize.css";
@import "~react-toggle/style.css";
@import "~@dpdgroupuk/mydpd-ui/styles/index";

.cursor-pointer {
  cursor: pointer;
}

.error-container {
  width: 257px;
  border: 0;
}

.mydpd-dndtable-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.col > .container {
  padding: 0;
}

.modal-header {
  color: #000;
}
