@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.tableContainer {
  overflow-y: auto;
  max-height: 246px;
}

.table {
  margin: 0;
  min-height: 244px;

  th {
    display: none;
  }

  tr {
    td {
      display: flex;
      min-height: 32px;
      padding: 0;
      word-break: break-all;
      overflow-x: auto;
      text-transform: none;
    }
  }

  tr:first-child td {
    border: none;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 7px 8px;
  background: #ececec;
  border: 1px solid #dee2e6;
  border-top: none;
}
