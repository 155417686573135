.tableContainer {
  tbody td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  > div:first-child {
    height: 205px; // 5 rows with borders
  }
}
