@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.name,
.value {
  font-size: 10px;
  line-height: 1;
  min-height: 10px;
}

.value {
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.name + .value::before {
  content: " - ";
}

.list {
  list-style-type: none;
  margin: 0 0 12px;
  padding: 0;

  li {
    line-height: 1;
  }

  li + li {
    margin-top: 7px;
  }
}

.invalid {
  color: $red;
}

.vertical {
  li {
    display: flex;
    flex-direction: column;
  }

  li + li {
    margin-top: 8px;
  }

  .name {
    font-weight: bold;
    margin-bottom: 4px;
  }

  .name + .value::before {
    display: none;
  }
}

.twoColumns {
  li {
    display: flex;
  }

  .name {
    display: block;
    flex-basis: 130px;
    flex-shrink: 0;
    font-weight: bold;
  }

  .value {
    display: block;
    flex-grow: 1;
    word-break: break-word;
  }

  .name + .value::before {
    display: none;
  }
}
