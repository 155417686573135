@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.footer {
  height: 52px;
}

.cardTitle {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  padding-right: 0;
}
