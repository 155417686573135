.editExporterDialog {
  max-height: 800px;

  :global #edit-exporter-modal {
    max-width: 692px;
  }
}

.helpModalContainer {
  text-align: justify;
}
