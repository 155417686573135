@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.col {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  > div {
    width: 100%;
  }
}

.text {
  font-size: 10px;
  line-height: 150%;
  white-space: pre-line;

  > p {
    margin: 0;
  }
}

.uppercase {
  text-transform: uppercase;
}

.card {
  padding-bottom: 6px;
}
