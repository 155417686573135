.roundedButton {
  border-radius: 4px;
  font-weight: normal;
  padding: 0 4px;
  height: 17px;
  font-size: 11px !important;
  line-height: 13px !important;
  letter-spacing: 0;
}

.tableContainer {
  overflow-y: hidden; // compensate Windows horizontal scroll for outer Container
  overflow-x: auto;
  height: 100%;

  table > tbody {
    display: grid !important;
    max-height: 105px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
