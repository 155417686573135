@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.btnLik {
  color: $white;

  &:focus,
  &:hover {
    color: $white;
    outline: none;
    text-decoration: none;
  }
}

.column {
  border-bottom: 1px solid $silver_2;
}
