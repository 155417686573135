.shipmentReview {
  margin: -7px 0;

  .col {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 14px;

  button + button {
    margin-left: 17px;
  }
}

.promptStyle {
  text-align: left;
}
