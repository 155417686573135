@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.mapPinContainer {
  cursor: pointer;
}

.pickupNumber {
  font-size: 18px;
  font-weight: 400;
  color: $black;
  position: absolute;
  top: 9px;
}
